import { Container } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = ({ children }: any) => {
    return (
        <>
            <Header />
            {children}
            <Container maxWidth="lg" style={{ paddingBottom: "80px" }}>
                <Footer />
            </Container>
        </>
    );
};

export default Layout;
