import { useEffect } from 'react';
import NoFooterLayout from '../../layout/NoFooterLayout';
import styles from './Order.module.scss';
import { Grid, Box, Container, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

const Order = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Order A Service | Handdi.io';
  }, []);

  return (
    <NoFooterLayout>
      <Container maxWidth="lg">
        <span onClick={() => navigate(-1)} className={styles.iconBack}>
          <ArrowBackIosIcon />
        </span>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Box className={styles.container}>
              <Box className={styles.headingContainer}>
                <p className={styles.heading}>Select Service Type</p>
              </Box>

              <Box className={styles.subtitleContainer}>
                <p className={styles.subtitle}>Select the type of service you’re looking for!</p>
              </Box>

              <Box className={styles.cardContainer}>
                <div className={`${styles.card} ${styles.activeCard}`} onClick={() => navigate('/order/aircon-service')}>
                  <img
                    src="/images/order_aircon.png"
                    alt="Aircon Services"
                    className={styles.cardButtonIcon}
                  />
                  <p>Aircon Services</p>
                </div>
              </Box>

              <Box style={{ marginBottom: '38px', textAlign: 'center' }}>
                <p className={`${styles.subtitle}`}>Coming Soon..</p>
              </Box>

              <Box style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                <div className={`${styles.card}`}>
                  <img
                    src="/images/order_cleaning.png"
                    alt="Cleaning Services"
                    className={styles.cardButtonIcon}
                  />
                  <p>Cleaning Services</p>
                </div>
              </Box>

              <Box style={{ marginBottom: '25px', display: 'flex', justifyContent: 'center' }}>
                <div className={`${styles.card}`}>
                  <img
                    src="/images/order_handyman.png"
                    alt="Handyman Services"
                    className={styles.cardButtonIcon}
                  />
                  <p>Handyman Services</p>
                </div>
              </Box>

              <Box style={{ marginBottom: '38px', marginTop: '70px' }}>
                <Stack direction="row" spacing={2} style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <p className={`${styles.subtitle}`}>Follow Us:</p>
                  <img
                    src="./images/footer_facebook_yellow.png"
                    alt="Facebook"
                    className={styles.footerIcon}
                    onClick={() => window.open('https://www.facebook.com/ServiceBackSG', '_blank')}
                  />
                  <img
                    src="./images/footer_linkedin_yellow.png"
                    alt="LinkedIn"
                    className={styles.footerIcon}
                    onClick={() => window.open('https://www.linkedin.com/company/serviceback/', '_blank')}
                  />
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </NoFooterLayout>
  );
};

export default Order;
