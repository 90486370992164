import {
    Home,
    Order,
    OrderAirconService,
    OrderAirconServiceSuccess,
    NotFound,
} from "./views";
import { Outlet, Navigate } from "react-router-dom";

interface IPrivateRoutes {
    auth: any;
    isMobile: boolean;
    isAndroid: boolean;
    isIOS: boolean;
    isPWA: boolean;
}

const privateRoutes = ({
    auth,
    isMobile = false,
    isAndroid = false,
    isIOS = false,
    isPWA = false,
}: IPrivateRoutes) => {
    const navigationRoute =
        isMobile && !isPWA
            ? isAndroid
                ? "/pwa/instruction/android"
                : isIOS
                ? "/pwa/instruction/ios"
                : "/pwa"
            : "/login";
    return [
        {
            path: "/",
            name: "Home",
            errorElement: <NotFound />,
            element:
                auth === null ? <Navigate to={navigationRoute} /> : <Outlet />,
            children: [
                {
                    path: "/",
                    name: "Home",
                    element: <Home />,
                },
                {
                    path: "/order",
                    name: "Order",
                    element: <Order />,
                },
                {
                    path: "/order/aircon-service",
                    name: "Order Aircon Service",
                    element: <OrderAirconService />,
                },
                {
                    path: "/order/aircon-service/success",
                    name: "Order Aircon Service Success",
                    element: <OrderAirconServiceSuccess />,
                },
            ],
        },
    ];
};

export default privateRoutes;
