import { Box, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./Home.module.scss";

import Layout from "../../layout/Layout";
import authStore from "../../store/authStore";
import Overview from "./Overview";
import Cleaning from "./Cleaning";

const Home = () => {
    const { auth } = authStore((state: any) => state);
    const [activeTab, setActiveTab] = useState<string>("overview");

    useEffect(() => {
        document.title = "Home | Handdi.io";
    }, []);

    return (
        <Layout>
            <Container maxWidth="lg">
                <Box className={styles.topHeader}>
                    <Typography
                        variant="h1"
                        style={{ fontSize: "30px", lineHeight: "35px" }}
                    >
                        Handdi Home Dashboard
                    </Typography>
                </Box>

                <Box className={styles.topTab}>
                    <button
                        className={`${
                            activeTab === "overview" && styles.tabButtonActive
                        } ${styles.tabButton}`}
                        onClick={() => setActiveTab("overview")}
                    >
                        Overview
                    </button>
                    <button
                        className={`${
                            activeTab === "aircon" && styles.tabButtonActive
                        } ${styles.tabButton}`}
                        onClick={() => setActiveTab("aircon")}
                    >
                        Aircon
                    </button>
                    <button
                        className={`${
                            activeTab === "cleaning" && styles.tabButtonActive
                        } ${styles.tabButton}`}
                        onClick={() => setActiveTab("cleaning")}
                    >
                        Cleaning
                    </button>
                </Box>

                {activeTab === "overview" && <Overview auth={auth} />}
                {activeTab === "aircon" && (
                    <Overview auth={auth} showInfoCards={false} />
                )}
                {activeTab === "cleaning" && <Cleaning />}
            </Container>
        </Layout>
    );
};

export default Home;
