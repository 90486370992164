import { Grid, Stack } from "@mui/material";
import styles from "./Home.module.scss";

const HomeCleaning = () => {
    return (
        <Grid container className={styles.simpleContainer} alignItems="center">
            <Grid item>
                <h2>Upcoming Appointments</h2>
                <p>
                    You have no upcoming cleaning appointments! Book a cleaning
                    service today!
                </p>
                <Stack
                    sx={{ mt: 2 }}
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                >
                    <a
                        href="https://wa.me/+6580434254?text=Book+Me+A+Servicing"
                        rel="noreferrer"
                        target="_blank"
                        className={`${styles.demoButton} theme-button-inverse`}
                    >
                        Book Service{" "}
                        <img src="/images/demo_book_icon.png" alt="Book Icon" />
                    </a>
                    <a
                        href="https://www.youtube.com/shorts/wuonIyCAItE"
                        rel="noreferrer"
                        target="_blank"
                        className={`${styles.demoButton} theme-button-inverse`}
                    >
                        Watch Demo{" "}
                        <img
                            src="/images/demo_watch_icon.png"
                            alt="Book Icon"
                        />
                    </a>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default HomeCleaning;
