import { useState } from 'react';
import { Box, TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';
import { Controller } from 'react-hook-form';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';

const ThemeTextField = styled(TextField)<TextFieldProps>(() => ({
  fontSize: '12px',
  lineHeight: '14px',
  "& label.Mui-focused": {
    color: "#000"
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#000"
    }
  }
}));

export const FormInput = ({ ...props }: any) => {
  const {
    control,
    name,
    type,
    size,
    label,
    disabled,
    rules,
    errors,
    style,
    defaultValue,
    multiline,
    minRows,
    onInputChange,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  let inputType = type;

  if (inputType === 'password') {
    inputType = showPassword ? 'text' : 'password';
  }

  const showPasswordAdornment = () => {
    return type === 'password' ? (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          onMouseDown={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    ) : (
      <></>
    );
  };

  return (
    <Box sx={style}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
        rules={rules || {}}
        render={({ field: { onChange, value } }) => (
          <ThemeTextField
            fullWidth
            multiline={multiline || false}
            minRows={minRows || 1}
            disabled={disabled || false}
            error={errors[name] !== undefined}
            helperText={errors[name] !== undefined ? errors[name]['message'] : ''}
            size={size ?? 'medium'}
            type={inputType}
            onChange={e => {
              onChange(e);
              if (onInputChange) {
                onInputChange(e.target.value);
              }
            }}
            value={value}
            label={label}
            autoComplete=""
            InputProps={{
              endAdornment: showPasswordAdornment(),
            }}
          />
        )}
      />
    </Box>
  );
};
