import { Box, Typography } from '@mui/material';
import styles from './AuthHeader.module.scss';

const AuthHeader = (props: any) => {
	const { title } = props;

	return (
		<div className={styles.container}>
			<Box style={{ marginBottom: '19px', textAlign: 'center' }}>
				<Typography variant="h1" component="h1" className={styles.title}>
					{title}
				</Typography>
			</Box>
		</div>
	);
};

export default AuthHeader;
