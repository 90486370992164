import { Outlet } from "react-router-dom";
import Login from "../pages/Login";
import { Index, InstructionAndroid, InstructionIos, NotFound } from "./views";

const publicRoutes = () => [
    {
        path: "/login",
        name: "Login",
        element: <Login />,
    },
    {
        path: "/pwa",
        name: "Home",
        errorElement: <NotFound />,
        element: <Outlet />,
        children: [
            {
                path: "/pwa",
                name: "Home",
                element: <Index />,
            },
            {
                path: "/pwa/instruction/android",
                name: "Instruction Android",
                element: <InstructionAndroid />,
            },
            {
                path: "/pwa/instruction/ios",
                name: "Instruction IOS",
                element: <InstructionIos />,
            },
        ],
    },
];

export default publicRoutes;
