export const serverEndpoint = "https://app.handdi.io:3000";

export const firebaseConfig = {
    apiKey: "AIzaSyA6Gl2veEmlrbYQbIexI7eti9gdDejOdHM",
    authDomain: "serviceback-db.firebaseapp.com",
    projectId: "serviceback-db",
    storageBucket: "serviceback-db.appspot.com",
    messagingSenderId: "15092038139",
    appId: "1:15092038139:web:ab41d747d7a171472a335e",
    measurementId: "G-73LLLXG1V9",
};
