import { Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import styles from "./InstallPrompt.module.scss";

const InstallPrompt = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={styles.container}
        >
            <Grid item>
                <h1 className={styles.header}>
                    <img src="./images/love.png" alt="love_icon" /> Welcome to
                    the Handdi Ecosystem{" "}
                    <img src="./images/love.png" alt="love_icon" />
                </h1>

                <Box
                    display="flex"
                    flexDirection="column"
                    className={styles.box}
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src="./images/cat.png" alt="cat_icon" />
                    <h2>Make your home dashboard a mobile app!</h2>
                    <p>
                        Get timely updates on all your services, utilize your
                        cashback for more cost savings and receive world class
                        support from our team by downloading our app
                    </p>
                    <Box className={styles.buttonsContainer}>
                        <Link
                            to="/pwa/instruction/ios"
                            className="theme-button-inverse"
                        >
                            I am an IOS User
                        </Link>
                        <Link
                            to="/pwa/instruction/android"
                            className="theme-button-inverse"
                        >
                            I am an Android User
                        </Link>
                        <a
                            href="https://wa.me/+6580434254?text=Hello%21"
                            className={styles.button}
                        >
                            Need Help? Contact Support Here!
                        </a>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default InstallPrompt;
