import { Box } from '@mui/material';
import styles from '../Order.module.scss';
import moduleStyles from './AirconServicePage2.module.scss';

interface AirconServicePage2Interface {
  days: string[];
  onSelectDay(day: string): void;
}

const availableDays: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const AirconServicePage2 = (props: AirconServicePage2Interface) => {
  const { days, onSelectDay } = props;

  return (
    <Box className={styles.container}>
      <Box className={styles.headingContainer}>
        <p className={styles.heading}>Preferred Timings</p>
      </Box>

      <Box className={styles.subtitleContainer}>
        <p className={styles.subtitle}>Select a day of the week you prefer our servicemen to head down!</p>
      </Box>

      <div className={moduleStyles.daySelector}>
        <p>*you may select multiple days</p>
        <div className={moduleStyles.selectorContainer}>
          {availableDays.map((d: string, i: number) => {
            return <div key={`day-${i}`} className={`
                ${moduleStyles.selectBox}
                ${days.includes(d) ? moduleStyles.activeBox : ''}
              `} onClick={() => onSelectDay(d)}>{d}</div>;
          })}
        </div>
      </div>
    </Box>
  );
};

export default AirconServicePage2;
