import { useState } from 'react';
import { Box } from '@mui/material';
import styles from '../AirconService.module.scss';
import { useSnackbar } from 'notistack';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FormButton } from '../../../components/Button/FormButton';

const AirconServiceCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();
  const [isPending, setisPending] = useState<boolean>(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      enqueueSnackbar('Stripe is not ready yet.', {
        variant: 'error',
      });
      return;
    }

    setisPending(true);

    const randomId = crypto.randomUUID();
    window.localStorage.setItem('aircon_service_checkout', randomId);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/order/aircon-service/success?uuid=${randomId}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('An unexpected error occured.', {
        variant: 'error',
      });
    }

    setisPending(false);
  };


  return (
    <Box className={styles.container}>
      <Box className={styles.headingContainer}>
        <p className={styles.heading}>Checkout</p>
      </Box>

      <Box className={styles.subtitleContainer}>
        <p className={styles.subtitle}>We are using Stripe to checkout</p>
      </Box>

      <PaymentElement />

      <FormButton
        style={{
          marginTop: 5
        }}
        type="button"
        fullWidth={true}
        loading={isPending}
        onClick={handleSubmit}
        label="Checkout"
      />
    </Box>
  );
};

export default AirconServiceCheckout;
