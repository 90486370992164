import { Box } from '@mui/material';
import ServiceCard from '../../../components/ServiceCard/ServiceCard';
import styles from '../AirconService.module.scss';
import { ItemInterface } from '../../../utils/types';

interface ServiceInterface {
  id: number;
  name: string;
  description: string;
  price: number;
  unitLabel: string;
}

interface AirconServicePage1Interface {
  onAddItem(item: ItemInterface): void;
}

const services = [
  {
    id: 1,
    name: "Chemical Wash",
    description:
      "Revitalize your aircon with our Chemical Wash. Say goodbye to dirt and enjoy fresh, cool air again.",
    price: 75,
    unitLabel: "unit",
  },
  {
    id: 2,
    name: "Jet Wash",
    description:
      "Give your aircon a powerful cleaning with our Jet Wash service. Experience enhanced cooling efficiency.",
    price: 75,
    unitLabel: "unit",
  },
  {
    id: 3,
    name: "Gas Top Up",
    description:
      "Ensure optimal performance with our Gas Top Up service. Keep your aircon running smoothly.",
    price: 80,
    unitLabel: "unit",
  },
  {
    id: 4,
    name: "Chemical Overhaul",
    description:
      "Restore your aircon's functionality with our thorough Chemical Overhaul. Experience improved air quality and efficiency.",
    price: 125,
    unitLabel: "unit",
  },
  {
    id: 5,
    name: "General Repairs",
    description:
      "Leave your aircon troubles to us. Our expert technicians will diagnose and fix any issues, restoring your aircon's functionality and comfort.",
    price: 40,
    unitLabel: "trip",
  },
  {
    id: 6,
    name: "General Servicing",
    description:
      "Keep your aircon in top shape with our affordable General Servicing. Trust us for reliable maintenance and a longer lifespan.",
    price: 20,
    unitLabel: "unit",
  },
];

const AirconServicePage1 = (props: AirconServicePage1Interface) => {
  const { onAddItem } = props;

  return (
    <Box className={styles.container}>
      <Box className={styles.headingContainer}>
        <p className={styles.heading}>Aircon Services</p>
      </Box>

      <Box className={styles.subtitleContainer}>
        <p className={styles.subtitle}>Discounted prices on all services with cashback!</p>
      </Box>

      {services.map((service: ServiceInterface, i: number) => {
        return (
          <ServiceCard {...service} key={`service-${i}`} onAddItem={(item: ItemInterface) => onAddItem(item)} />
        );
      })}
    </Box>
  );
};

export default AirconServicePage1;
