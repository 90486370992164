import {
    Paper,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
} from "@mui/material";
import styles from "./Table.module.scss";
import { useState, useEffect, ChangeEvent } from "react";
import { ThemeTableDark } from "../Table/ThemeTable";
import { ThemeTablePaginationDark } from "../Table/ThemeTable";

interface Column {
    id: "service" | "date" | "contractSize" | "cashback";
    label: string;
    minWidth?: number;
    align?: "center";
    format?: (value: any) => string;
}

interface Data {
    service: string;
    date: string;
    contractSize: number;
    cashback: number;
}

const columns: readonly Column[] = [
    { id: "service", label: "Service", minWidth: 170 },
    { id: "date", label: "Order Date", minWidth: 100 },
    {
        id: "contractSize",
        label: "Contract Size",
        minWidth: 100,
        format: (value: number) => {
            return `$${value.toLocaleString()}`;
        },
    },
    {
        id: "cashback",
        label: "Cashback Earned",
        minWidth: 170,
        align: "center",
        format: (value: number) => {
            return `$${value.toLocaleString()}`;
        },
    },
];

const OrderHistoryTable = (props: any) => {
    const { rowData, loading } = props;
    const [rows, setRows] = useState<Data[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event: React.MouseEvent | null, page: number) => {
        console.log("event", event);
        setPage(page);
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        setRows(rowData);
    }, [rowData]);

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                backgroundColor: "#000",
                padding: "35px 31px",
            }}
        >
            <TableContainer
                sx={{
                    maxHeight: 440,
                    "&::-webkit-scrollbar": {
                        width: 5,
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#000",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#DCD7D7",
                        borderRadius: 10,
                    },
                }}
            >
                <ThemeTableDark stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <h3 className={styles.title}>Order History</h3>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell>loading...</TableCell>
                            </TableRow>
                        ) : (
                            <>
                                {rows
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage,
                                    )
                                    .map((row, i) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={`row-${i}`}
                                            >
                                                {columns.map((column) => {
                                                    const value =
                                                        row[column.id];
                                                    return (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                        >
                                                            {column.format &&
                                                            typeof value ===
                                                                "number"
                                                                ? column.format(
                                                                      value,
                                                                  )
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </>
                        )}
                    </TableBody>
                </ThemeTableDark>
            </TableContainer>
            <ThemeTablePaginationDark
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default OrderHistoryTable;
