import { useEffect, useState } from 'react';
import NoFooterLayout from '../../layout/NoFooterLayout';
import styles from './AirconServiceSuccess.module.scss';
import { Box, Grid, Container, Link } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ItemInterface } from '../../utils/types';
import authStore from '../../store/authStore';

interface ServiceCheckoutDetailInterface {
  items: ItemInterface[];
  days: string[];
  times: string[];
  notes?: string;
  grandTotal: number;
}

const AirconServiceSuccess = () => {
  const { auth } = authStore((state: any) => state);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const [searchParams, setSearchParams]: any = useSearchParams();
  const [isAfterCheckout, setIsAfterCheckout] = useState<boolean>(false);

  const fireZapier = async (details: ServiceCheckoutDetailInterface) => {
    const postData: any = {
      ...details,
      totalItems: details.items.length || 0,
      airtableUserId: auth.airtableUserId,
      name: auth.name,
      phoneNumber: auth.phoneNumber,
    };
    await fetch(`https://hooks.zapier.com/hooks/catch/15150051/3t1yoe4/`, {
      method: 'POST',
      body: JSON.stringify(postData),
    }).catch(err => {
      console.log({ err });
    });
  };

  useEffect(() => {
    document.title = 'Checkout | Handdi.io';
    const uuid = searchParams.get('uuid');
    if (uuid !== null) {
      if (uuid === window.localStorage.getItem('aircon_service_checkout')) {
        setIsAfterCheckout(true);
        const details: string | null = window.localStorage.getItem('aircon_service_details');
        const checkoutDetails = JSON.parse(details || '');
        fireZapier(checkoutDetails);
      }
    }
  }, []);

  return (
    <NoFooterLayout>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '85vh' }}
        >
          {isAfterCheckout ? (
            <Grid item className={styles.container}>
              <h1>Thank you for your order</h1>
              <p>Our team will be processing your order and will be in touch with you.</p>
              <Box>
                <Link href="/">Back To Home</Link>
              </Box>
              <Box>
                <Link href="/order">Order another service</Link>
              </Box>
            </Grid>
          ) : (
            <Grid item className={styles.container}>
              <h1>Please get out from this page</h1>
              <p>You either reach this page by mistake or trying something funny.</p>
              <Box>
                <Link href="/">Back To Home</Link>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </NoFooterLayout>
  );
};

export default AirconServiceSuccess;
