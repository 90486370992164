import { Table, TablePagination, TableProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ThemeTableDark = styled(Table)<TableProps>(() => ({
  backgroundColor: '#000',
  color: '#fff',
  borderRadius: '10px',
  '& .MuiTableCell-root': {
    backgroundColor: '#000',
    color: '#fff',
    border: 'none',
    fontSize: '12px',
    lineHeight: '14.06px',
    fontWeight: '700',
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '18.75px'
  },
}));

export const ThemeTablePaginationDark = styled(TablePagination)<any>(() => ({
  backgroundColor: '#000',
  color: '#fff',
}));
