import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  auth: object | null;
  setAuthData: (params: object) => void;
  clearAuthData: () => void;
}

const authStore = create(
  persist<State>((set) => ({
    auth: null,
    setAuthData: (params: object) => {
      set(() => ({
        auth: params,
      }));
    },
    clearAuthData: () => {
      set(() => ({
        auth: null,
      }));
    },
  }),
  { name: 'auth' }
  )
);

export default authStore;
