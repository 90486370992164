import Header from '../components/Header';

const NoFooterLayout = ({ children }: any) => {
  return (
    <>
      <Header isHideRefresh={true} isSupportButton={true} />
      {children}
    </>
  );
};

export default NoFooterLayout;
