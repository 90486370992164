import { useEffect, useState } from 'react';
import NoFooterLayout from '../../layout/NoFooterLayout';
import styles from './AirconService.module.scss';
import { Grid, Container } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AirconServiceCart from './modules/AirconServiceCart';
import AirconServicePage1 from './modules/AirconServicePage1';
import AirconServicePage2 from './modules/AirconServicePage2';
import AirconServicePage3 from './modules/AirconServicePage3';
import AirconServiceCheckout from './modules/AirconServiceCheckout';
import { ItemInterface, DiscountConfigInterface } from '../../utils/types';

import { serverEndpoint } from '../../config/api';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const discountConfig: DiscountConfigInterface = {
  total: 5,
  limit: 175,
};

const stepButtonConfig: string[] = [
  'Next: Select Day',
  'Next: Select Time',
  'Next: Checkout',
  'Confirm Order',
];

const OrderAirconService = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [items, setItems] = useState<ItemInterface[]>([]);
  const [days, setDays] = useState<string[]>([]);
  const [times, setTimes] = useState<string[]>([]);
  const [notes, setNotes] = useState<string>('');

  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [hasDiscount, setHasDiscount] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<string>('');

  const setFormData = () => {
    window.localStorage.setItem('aircon_service_details', JSON.stringify({
      items,
      days,
      times,
      notes,
      grandTotal
    }));
  };

  const setStripeIntent = async () => {
    await fetch(`${serverEndpoint}/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount: grandTotal * 100,
      }),
    }).then(async (result: any) => {
      const { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  };

  const addItem = (item: ItemInterface) => {
    if (item.qty <= 0) {
      enqueueSnackbar(`You need to order at least 1 unit.`, {
        variant: 'error',
      });
      return false;
    }

    const clonedItems = [...items];

    if (clonedItems.filter(i => i.id === item.id).length > 0) {
      enqueueSnackbar(`Item already in cart. Remove item from cart if you need to adjust number of units.`, {
        variant: 'warning',
      });
      return false;
    }

    clonedItems.push(item);
    setItems(clonedItems);

    enqueueSnackbar(`Item added to cart.`, {
      variant: 'success',
    });
  };

  const removeItem = (id: number) => {
    const clonedItems = items.filter(i => i.id !== id);
    setItems(clonedItems);

    enqueueSnackbar(`Item removed from cart.`, {
      variant: 'warning',
    });
  };

  const selectDay = (day: string) => {
    const clonedDays = [...days];
    if (clonedDays.filter(d => d === day).length > 0) {
      setDays(clonedDays.filter(d => d !== day));
    } else {
      clonedDays.push(day);
      setDays(clonedDays);
    }
  };

  const selectTime = (time: string) => {
    const clonedTimes = [...times];
    if (clonedTimes.filter(t => t === time).length > 0) {
      setTimes(clonedTimes.filter(t => t !== time));
    } else {
      clonedTimes.push(time);
      setTimes(clonedTimes);
    }
  };

  const onNextStep = () => {
    if (items.length <= 0) {
      enqueueSnackbar(`No item in cart yet.`, {
        variant: 'error',
      });
      return false;
    }

    if (step === 2 && days.length <= 0) {
      enqueueSnackbar(`You need to select at least 1 day.`, {
        variant: 'error',
      });
      return false;
    }

    if (step === 3) {
      setFormData();
      setStripeIntent();
    }

    if (step >= 1 && step < 4) {
      setStep(step => step + 1);
    }
  };

  const onPrevStep = () => {
    if (step >= 1 && step <= 4) {
      setStep(step => step - 1);
    }
  };

  // const onReset = () => {
  //   setItems([]);
  //   setDays([]);
  //   setTimes([]);
  //   setNotes('');
  //   setGrandTotal(0);
  //   setHasDiscount(false);
  //   setStep(1);
  // };

  useEffect(() => {
    document.title = 'Order Aircon Service | Handdi.io';
  }, []);

  useEffect(() => {
    fetch(`${serverEndpoint}/config`).then(async r => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    let priceTotal: number = items.reduce((i, { total }) => i + total, 0);

    if (priceTotal >= discountConfig.limit) {
      setHasDiscount(true);
      priceTotal -= discountConfig.total;
    } else {
      setHasDiscount(false);
    }

    setGrandTotal(priceTotal);
  }, [items]);

  return (
    <NoFooterLayout>
      <Container maxWidth="lg" style={{ marginBottom: '100px' }}>
        <span onClick={() => navigate(-1)} className={styles.iconBack}>
          <ArrowBackIosIcon />
        </span>
        <Grid
          container
          spacing={5}
          direction="row"
          alignItems="top"
          justifyContent="center"
          style={{ minHeight: '85vh' }}
        >
          <Grid item className={styles.leftContainer}>
            {step === 1 && <AirconServicePage1 onAddItem={addItem} />}
            {step === 2 && <AirconServicePage2 days={days} onSelectDay={selectDay} />}
            {step === 3 && (
              <AirconServicePage3
                times={times}
                onSelectTime={selectTime}
                notes={notes}
                onChangeNotes={s => setNotes(s)}
              />
            )}
            {step === 4 && clientSecret !== '' && stripePromise !== null && (
              <Elements
                stripe={stripePromise}
                options={{
                  clientSecret,
                  appearance: {
                    theme: 'night',
                  },
                }}
              >
                <AirconServiceCheckout />
              </Elements>
            )}
          </Grid>

          {step < 5 && (
            <Grid item className={styles.rightContainer}>
              <AirconServiceCart
                items={items}
                grandTotal={grandTotal}
                hasDiscount={hasDiscount}
                discountConfig={discountConfig}
                onRemoveItem={removeItem}
              />

              <div className={styles.stepButtonContainer}>
                {step < 4 && (
                  <div className={styles.stepNextButton} onClick={onNextStep}>
                    <p>{stepButtonConfig[step - 1]}</p>
                  </div>
                )}
                {step > 1 && (
                  <div className={styles.stepPrevButton} onClick={onPrevStep}>
                    <p>Back</p>
                  </div>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </Container>
    </NoFooterLayout>
  );
};

export default OrderAirconService;
