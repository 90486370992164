import { Stack, CardContent } from '@mui/material';
import { ThemeCardDark } from '../Card/ThemeCard';
import styles from './Card.module.scss';

const CardCashback = (props: any) => {
  const { auth } = props;

  return (
    <ThemeCardDark className={styles.card}>
      <CardContent className={styles.cardBody}>
        <Stack spacing={1}>
          <h3 className={styles.cardHeading}>${auth.cashback?.toFixed(2)}</h3>
          <p className={styles.cardSubtitle}>
            Cashback <br /> Earned
          </p>
        </Stack>
      </CardContent>
    </ThemeCardDark>
  );
};

export default CardCashback;
