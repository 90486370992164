import { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { FormButton } from "../Button/FormButton";
import styles from "./Header.module.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import RefreshIcon from "@mui/icons-material/Refresh";
import authStore from "../../store/authStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { enqueueSnackbar } from "notistack";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Header = (props: any) => {
    const { isSupportButton = false, isHideRefresh = false } = props;
    const { auth, setAuthData } = authStore((state: any) => state);
    const matches = useMediaQuery((theme: any) => theme.breakpoints.up("sm"));
    const navigate = useNavigate();

    const [appLoading, setAppLoading] = useState<boolean>(false);

    const refreshRecord = async () => {
        setAppLoading(true);
        try {
            const response = await axios.get(`${BACKEND_URL}/users/user`, {
                headers: {
                    Authorization: `Bearer ${auth.firebaseIdToken}`,
                },
            });
            const { user } = response.data;
            if (!user) {
                return enqueueSnackbar(
                    "Something went wrong, please try again",
                    {
                        variant: "error",
                    },
                );
            }
            setAuthData({
                ...user,
                firebaseIdToken: auth.firebaseIdToken,
            });
        } catch (err: any) {
            enqueueSnackbar(err.response.data.error, {
                variant: "error",
            });
            setAppLoading(false);
        }
        setAppLoading(false);
        window.location.reload();
    };

    return (
        <>
            <Grid
                container
                className={styles.container}
                alignItems="center"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        onClick={() => navigate("/")}
                        variant="h6"
                        component="h1"
                        className={styles.iconText}
                    >
                        Handdi.io
                    </Typography>
                </Grid>
                <Grid xs={3} item className={styles.topButtonContainer}>
                    <Stack
                        direction="row"
                        spacing={1}
                        className={`${styles.topButtonStack} ${
                            isSupportButton ? styles.topButtonSingle : ""
                        }`}
                    >
                        {matches && !isHideRefresh && (
                            <FormButton
                                type="button"
                                theme="custom"
                                label="Refresh Data"
                                variant="refresh"
                                buttonIcon={<RefreshIcon sx={{ mr: 1 }} />}
                                loading={appLoading}
                                onClick={refreshRecord}
                            />
                        )}
                        <a
                            className={`${styles.button} ${styles.buttonSupport}`}
                            href="https://wa.me/+6580434254?text=Hello%21"
                            target="_blanl"
                        >
                            Support{" "}
                            <img
                                src="/images/message.png"
                                alt="Cart"
                                className={styles.buttonIcon}
                            />
                        </a>{" "}
                        <a
                            className={`${styles.button} ${styles.buttonSupport}`}
                            href="https://wa.me/+6580434254?text=Hello%21"
                        >
                            Book Service{" "}
                            <img
                                src="/images/cart.png"
                                alt="Cart"
                                className={styles.buttonIcon}
                            />
                        </a>
                    </Stack>
                </Grid>
            </Grid>
            <div className={styles.spaceContainer}></div>
        </>
    );
};

export default Header;
