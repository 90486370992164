import React from "react";

const OfflineScreen = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "70vh",
                textAlign: "center",
                padding: "0 1rem",
            }}
        >
            <h1>Handdi.io</h1>
            <p
                style={{
                    fontSize: "1.2rem",
                    margin: "1rem 0",
                }}
            >
                Oops! It seems you're offline. Please check your internet
                connection.
            </p>
        </div>
    );
};

export default OfflineScreen;
