import { Box } from '@mui/material';
import styles from '../Order.module.scss';
import moduleStyles from './AirconServicePage3.module.scss';

interface AirconServicePage3Interface {
  times: string[];
  notes: string;
  onSelectTime(time: string): void;
  onChangeNotes(notes: string): void;
}

const availableTimes: string[] = ['9am - 11am', '12pm - 3pm', '4pm - 7pm'];

const AirconServicePage3 = (props: AirconServicePage3Interface) => {
  const { times, notes, onSelectTime, onChangeNotes } = props;

  return (
    <Box className={styles.container}>
      <Box className={styles.headingContainer}>
        <p className={styles.heading}>Preferred Time Slots</p>
      </Box>

      <Box className={styles.subtitleContainer}>
        <p className={styles.subtitle}>What are your preferred time slots within those days?</p>
      </Box>

      <div className={moduleStyles.timeSelector}>
        <p>*you may select multiple time slots</p>
        <div className={moduleStyles.selectorContainer}>
          {availableTimes.map((t: string, i: number) => {
            return <div key={`time-${i}`} className={`
                ${moduleStyles.selectBox}
                ${times.includes(t) ? moduleStyles.activeBox : ''}
              `} onClick={() => onSelectTime(t)}>{t}</div>;
          })}
        </div>

        <div className={moduleStyles.notesContainer}>
          <p>Any additional comments for the team?</p>
          <textarea rows={5} className={moduleStyles.textField} value={notes} placeholder="Let us know if you have additional comments!" name="notes" onChange={(e) => onChangeNotes(e.target.value)} />
        </div>
      </div>
    </Box>
  );
};

export default AirconServicePage3;
