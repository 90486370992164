import { Box } from '@mui/material';
import styles from './AirconServiceCart.module.scss';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { ItemInterface, DiscountConfigInterface } from '../../../utils/types';

interface AirconServiceCartInterface {
  items: ItemInterface[];
  hasDiscount: boolean;
  discountConfig: DiscountConfigInterface;
  grandTotal: number;
  onRemoveItem(id: number): void;
}

const AirconServiceCart = (props:AirconServiceCartInterface) => {
  const { items, hasDiscount, discountConfig, grandTotal, onRemoveItem } = props;

  return (
    <Box className={styles.container}>
      <h3>Items</h3>
      <ul className={styles.cartList}>
        {items.map((item: ItemInterface, i: number) => {
          return (
            <li key={`cart-${i}`}>
              <div>
                <RemoveCircleIcon onClick={() => onRemoveItem(item.id)} className={styles.cartRemoveIcon} />
                <p>
                  {item.name} {item.qty}x
                </p>
              </div>
              <span className={styles.totalPrice}>${item.total.toLocaleString()}</span>
            </li>
          );
        })}
      </ul>

      {hasDiscount && (
        <div className={styles.discountContainer}>
          <h3>Discounts</h3>
          <ul className={styles.discountList}>
            <li>
              <div>
                <p>Members Discount</p>
              </div>
              <span>${discountConfig.total}</span>
            </li>
          </ul>
        </div>
      )}

      <div className={styles.grandTotalContainer}>
        <p>Total</p>
        <span>${grandTotal.toLocaleString()}</span>
      </div>
    </Box>
  );
};

export default AirconServiceCart;
