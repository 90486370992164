import { useState } from 'react';
import { Box } from '@mui/material';
import styles from './ServiceCard.module.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { ItemInterface } from '../../utils/types';

interface ServiceCardInterface {
  key: string;
  id: number;
  name: string;
  price: number;
  description: string;
  unitLabel: string;
  onAddItem(item: ItemInterface): void;
}

const ServiceCard = (props: ServiceCardInterface) => {
  const { id, name, price, description, unitLabel, onAddItem } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [unitCount, setUnitCount] = useState<number>(0);

  const addItemToCart = () => {
    onAddItem({
      id: id,
      name: name,
      price: price,
      qty: unitCount,
      total: price * unitCount,
      unitLabel: unitLabel,
    });
  };

  return (
    <Box className={styles.container}>
      <div
        className={styles.card}
        onClick={() => setOpen(!open)}
      >
        <p>{name} (${price.toLocaleString()} / {unitLabel})</p>
        <div className={styles.iconContainer}>
          {open ? <RemoveIcon /> : <AddIcon />}
        </div>
      </div>
      {open && (
        <div className={styles.cardContent}>
          <div className={styles.cardContentDescription}>{description}</div>
          <div className={styles.counterContainer}>
            <p>Number of Units</p>
            <div className={styles.counter}>
              <RemoveIcon className={styles.counterIcon} onClick={() => setUnitCount((unitCount) => unitCount - 1)} />
              {unitCount}
              <AddIcon className={styles.counterIcon} onClick={() => setUnitCount((unitCount) => unitCount + 1)} />
            </div>
          </div>
          <p className={styles.addToCartLabel} onClick={addItemToCart}>
            <AddShoppingCartIcon /> Add To Cart
          </p>
        </div>
      )}
    </Box>
  );
};

export default ServiceCard;
